<template>
    <div id="services">
      <div class="container-fluid">
        <!-- Add Edit Section -->
        <!-- @submit="onSubmit" @reset="onReset" -->
        <div class="add-section" v-if="canAccess(['store-service','update-service'])">
          <b-modal id="services-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('sidebar.services')" @ok="submit">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <label for="">{{ $t('ar-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.ar_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('en-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.en_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('kr-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.kr_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('service-type') }}</label>
                <b-form-select
                  v-model="payload.service_type"
                  :options="ServicesTypeOptions"
                >
                <template #first>
                        <b-form-select-option value=""> {{ $t('select-service-type') }} </b-form-select-option>
                      </template>
                </b-form-select>
              </div>
              <div class="feild">
                <label for="">Icon</label>
                <v-select v-model="payload.icon" placeholder="Select Service Icon" :options="icons" label="title" :reduce="(option)=>option.icon">
                  <template v-slot:option="option">
                    <i :class="option.icon"></i>
                    <span class="mx-1">
                      {{ option.title }}
                    </span>
                  </template>
                </v-select>
              </div>
            </b-form>
          </b-modal>
        </div>
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-input
                      type="text"
                      :placeholder="$t('filter-name')"
                      v-model="filterData.name"
                    ></b-form-input>
                  </b-col>
                  <b-col sm="12" md="6">
                      <b-form-select
                        v-model="filterData.service_type"
                        :options="ServicesTypeOptions"
                        class="h-100"
                      >
                      <template #first>
                        <b-form-select-option value=""> {{ $t('select-service-type') }} </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-col>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('services-list') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button v-if="canAccess(['store-service'])" variant="primary" @click="addInit" size="sm">{{ $t('add') }}</b-button>
            </template>
            <template v-slot:body>
              <b-table v-if="canAccess(['get-service','update-service','delete-service'])" responsive :items="services.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(icon)="data">
                    <i :class="data.item.icon"></i>
                </template>
                <template v-slot:cell(service_type)="data">
                  <h5>
                    <b-badge :variant="ServicesType.getByValue(data.item.service_type).classes">{{$t(ServicesType.getByValue(data.item.service_type).label)}}</b-badge>
                  </h5>
                </template>
                <template v-slot:cell(actions)="data">
                    <div class="actions-list d-flex align-items-center">
                      <b-button v-if="canAccess(['update-service'])" v-b-tooltip.top="$t('edit')" variant=" iq-bg-success" @click="update(data.item)"  size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                      <!-- <b-button v-if="canAccess(['delete-service'])" v-b-tooltip.top="$t('remove')" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="services.total"
                  :per-page="services.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
import { ServicesType } from '@/enums'
export default {
  name: 'services',
  data () {
    return {
      filterData: {
        page: 1,
        name: '',
        service_type: 0
      },
      payload: {
        ar_name: '',
        en_name: '',
        kr_name: '',
        service_type: '',
        icon: 'ri-remixicon-line'
      },
      icons: [
        {
          icon: 'fas fa-utensils',
          title: 'Food'
        },
        {
          icon: 'fas fa-glass-martini-alt',
          title: 'Bar'
        },
        {
          icon: 'fas fa-dumbbell',
          title: 'Gym'
        },
        {
          icon: 'fas fa-swimmer',
          title: 'Pool'
        },
        {
          icon: 'fas fa-hot-tub',
          title: 'Sauna'
        },
        {
          icon: 'fas fa-concierge-bell',
          title: 'Room Service'
        },
        {
          icon: 'fas fa-gamepad',
          title: 'Game Room'
        },
        {
          icon: 'fas fa-parking',
          title: 'Parking'
        }
      ]
    }
  },
  computed: {
    ServicesType () {
      return ServicesType
    },
    ServicesTypeOptions () {
      return ServicesType.asList().map((item) => {
        return {
          text: this.$t(item.label),
          value: item.value
        }
      })
    },
    fields () {
      return [
        {
          label: 'Icon',
          key: 'icon'
        },
        {
          label: this.$t('name'),
          key: `${this.$i18n.locale}_name`
        },
        {
          label: this.$t('service-type'),
          key: 'service_type'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('created-by'),
          key: 'created_by.name'
        },
        {
          label: this.$t('updated-date'),
          key: 'updated_at'
        },
        {
          label: this.$t('updated-by'),
          key: 'updated_by.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getServices(this.filterData)
    },
    addInit () {
      this.$bvModal.show('services-modal')
    },
    update (data) {
      Object.assign(this.payload, data)
      this.$bvModal.show('services-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeServices(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addServices(this.payload).then(() => {
          this.$bvModal.hide('services-modal')
          this.payload = this.resetObject(this.payload)
          this.getServices()
        })
      } else {
        this.updateServices(this.payload).then(() => {
          this.$bvModal.hide('services-modal')
          this.payload = this.resetObject(this.payload)
          this.getServices()
        })
      }
    },
    changeStatus () {},
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getServices(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getServices()
  }
}
</script>
